const oneClick = () => {
    const mainCatalog = document.querySelector('.main-catalog');
    const good = document.querySelector('.good');

    if (mainCatalog) {
        const appendCardData = (c, modal) => {
            // В карточке по которой кликнули находим нужные нам элементы
            const category = c.querySelector('.card-category').cloneNode(true);
            const title = c.querySelector('.card-title').cloneNode(true);
            const prop = c.querySelector('.card-prop').cloneNode(true);
            const dataContainer = modal.querySelector('.page-modal__data');
            // Вставляем элементы в контейнер
            dataContainer.innerHTML = '';
            dataContainer.append(category);
            dataContainer.append(title);
            dataContainer.append(prop);
        };

        const openModal = (e) => {
            let target = e.target;

            if (target.matches('.card__buy')) {
                target = target.closest('.card'); // находим карточку товара в которой лежит кнопка
                appendCardData(target, document.querySelector('.modal-buy-one-click')); // Ф-ция добавления данных в модалку
                document.querySelector('.page-modal').classList.add('active'); // Открыть оверлей
                document.querySelector('.modal-buy-one-click').classList.add('active'); // Открыть модалку
            }

            if (target.matches('.card__order')) {
                target = target.closest('.card');
                appendCardData(target, document.querySelector('.modal-to-order'));
                document.querySelector('.page-modal').classList.add('active');
                document.querySelector('.modal-to-order').classList.add('active');
            }

        };
        mainCatalog.addEventListener('click', openModal);
        if (good) {
            good.addEventListener('click', openModal);
        }
    }


};

export default oneClick;