const tabs = () => {
    const tabNavs = document.querySelectorAll(".good-tabs__item");
    const tabPanes = document.querySelectorAll(".tab-content__item");

    for (let i = 0; i < tabNavs.length; i++) {

        tabNavs[i].addEventListener("click", evt => {
            evt.preventDefault();
            const target = evt.target;
            const activeTabAttr = target.getAttribute("data-tab");

            for (let j = 0; j < tabNavs.length; j++) {
                const contentAttr = tabPanes[j].getAttribute("data-tab-content");

                if (activeTabAttr === contentAttr) {
                    tabNavs[j].classList.add("active");
                    tabPanes[j].classList.add("active");
                } else {
                    tabNavs[j].classList.remove("active");
                    tabPanes[j].classList.remove("active");
                }
            }
            ;
        });
    }
};

export default tabs;