import {checkoutDesktop} from "./checkoutBtnAnimation";
import {checkoutMobile} from "./checkoutBtn";

const checkoutBtnHandle = () => {

    // Ищем кнопки "добавить в корзину"
    const checkoutButtons = document.querySelectorAll('.checkout-btn');

    if (window.screen.availWidth > 1000) {
        checkoutButtons.forEach(checkoutDesktop);

    } else {
        checkoutButtons.forEach(checkoutMobile);
    }
};

export default checkoutBtnHandle;