const openFilter = () => {
    const filterBtn = document.querySelector('.filter__show');

    const showFilter = () => {
        const filter = document.querySelector('.filter');
        const filterContainer = document.querySelector('.filter__container');

        filterBtn.classList.toggle('active');
        filter.classList.toggle('active');
        filterContainer.classList.toggle('active');
    };

    if (!filterBtn) return

    filterBtn.addEventListener('click', showFilter);
};

export default openFilter;