const seo = () => {
    const btnMore = document.querySelector('.seo button');

    const moreHandle = () => {
        const paragraphs = document.querySelectorAll('.seo p');

        btnMore.style.display = "none";
        paragraphs[paragraphs.length - 1].style.display = 'block';
    };

    if (!btnMore) return

    btnMore.addEventListener('click', moreHandle);
};

export default seo;