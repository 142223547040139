const closeModal = () => {
    const pageModal = document.querySelector('.page-modal');

    const removeClass = () => {
        pageModal.classList.remove('active');
        pageModal.querySelector('.page-modal__body.active').classList.remove('active')
    };

    const closeModalHandle = (e) => {
        let target = e.target;

        if (target.matches('.page-modal__close')) {
            removeClass();
        }

        if (target.matches('.page-modal')) {
            removeClass();
        }
    };

    pageModal.addEventListener('click', closeModalHandle);
};

export default closeModal;