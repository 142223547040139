const openMenu = () => {
    const burger = document.querySelector('.burger');
    const menu = document.querySelector('.catalog');

    const openMenuHandle = () => {
        menu.style.left = '0';
        document.querySelector('body').style.overflowY = 'hidden';
    };

    burger.addEventListener('click', openMenuHandle);
};

export default openMenu;