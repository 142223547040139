const deleteOrder = () => {
   const orders = document.querySelectorAll('.order');

    orders.forEach(order => {
        const deleteOrderHandler = e => {
            const target = e.target;
            if (target.closest('.order__del')) {
                order.remove();
            }
        };
        order.addEventListener('click', deleteOrderHandler)
    });
};

export default deleteOrder;