import searchCheckoutBtn from "./searchCheckoutBtn";

export const checkoutMobile = (btn) => {
    //Вызываем ф-цию поиска элементов и деструктурируем массив
    const [btnText, btnIcon, btnSuccess] = searchCheckoutBtn(btn);

    btn.addEventListener('click', () => {

        btnText.style.opacity = '0';
        btnIcon.style.opacity = '0';
        btn.style.background = '#79CE88';
        btnSuccess.style.opacity = '1';

        setTimeout(() => {
            btnText.style.opacity = '';
            btnIcon.style.opacity = '';
            btn.style.background = '';
            btnSuccess.style.opacity = '';
        }, 3000);
    });
};