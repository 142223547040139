import Swiper from 'swiper/bundle'

const sliders = () => {
    const mainSlider = new Swiper('.main-slider .swiper-container', {
        navigation: {
            nextEl: '.main-slider .swiper-button-next',
            prevEl: '.main-slider .swiper-button-prev',
        },
        breakpoints: {
            320: {
                pagination: {
                    el: '.main-slider .swiper-pagination',
                    clickable: true
                },
                navigation: false
            },
            1000: {
                pagination: false
            }
        }
    });

    const newsSlider = new Swiper('.news-slider .swiper-container', {
        navigation: {
            nextEl: '.news-slider .swiper-button-next',
            prevEl: '.news-slider .swiper-button-prev',
        },
        pagination: {
            el: '.news-slider .swiper-pagination',
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1000: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1440: {
                slidesPerView: 3,
                spaceBetween: 20
            }
        }
    });

    const cardSliderSmall = new Swiper(".good-slider__small", {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
    const cardSliderBig = new Swiper(".good-slider__big", {
        loop: true,
        spaceBetween: 10,
        navigation: false,
        thumbs: {
            swiper: cardSliderSmall,
        },
    });
};

export default sliders;