const addFavorit = () => {
   const mainCatalog = document.querySelector('.main-catalog');
   const good = document.querySelector('.good');

   const addFavoritHandle = (e) => {
      const target = e.target;
      if (target.matches('.card__favorites')) {
         target.classList.toggle('full');
      }
   };

   if (mainCatalog) {
      mainCatalog.addEventListener('click', addFavoritHandle);
   }
   if (good) {
      good.addEventListener('click', addFavoritHandle);
   }

};

export default addFavorit;