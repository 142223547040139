const closeCatalog = () => {
    const closeBtn = document.querySelector('.catalog__close');

    const closeCatalogHandle = (e) => {
        const catalog = document.querySelector('.catalog');
        catalog.style.left = '';
        document.querySelector('body').style.overflowY = '';
    };

    closeBtn.addEventListener('click', closeCatalogHandle);
};

export default closeCatalog;