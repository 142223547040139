//Импорт файла стилей
import '../scss/main.scss';
//Импорт файлов разметки
import '../index.html';
import '../catalog.html';
import '../card.html';
import '../order.html';


//Импорт функций
import checkoutBtnHandle from "./modules/checkoutBtn/checkoutBtnHandle";
import openMenu from "./modules/catalog/openMenu";
import openSubmenu from "./modules/submenu/openSubmenu";
import closeSubmenu from "./modules/submenu/closeSubmenu";
import closeCatalog from "./modules/catalog/closeCatalog";
import sliders from "./modules/sliders";
import closeModal from "./modules/modal/closeModal";
import oneClick from "./modules/oneClick";
import addFavorit from "./modules/addFavorit";
import maskPhone from "./modules/maskPhone";
import fancybox from "./modules/fancybox";
import search from "./modules/search";
import openFilter from "./modules/filters/openFilter";
import seo from "./modules/seo";
import toggleProps from "./modules/toggleProps";
import video from "./modules/video";
import tabs from "./modules/tabs";
import deleteOrder from "./modules/deleteOrder";
import goodCounter from "./modules/goodCounter";
import openMap from "./modules/openMap";

// Вызовы ф-ций
checkoutBtnHandle();
openMenu();
openSubmenu();
closeSubmenu();
closeCatalog();
sliders();
closeModal();
oneClick();
maskPhone('input[type="tel"]');
addFavorit();
fancybox();
search();

if (document.querySelector('.filter')) {
    import('./modules/filters/filterRange').then(data => {
        data.filterRange();
    });
    import('./modules/filters/customSelect').then(data => {
        data.customSelect();
    });
}
openFilter();
seo();
toggleProps();
tabs();
video();
deleteOrder();
goodCounter();
openMap();
