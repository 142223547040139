const openSubmenu = () => {
    const catalogMenu = document.querySelector('.catalog__menu');

    const openSubmenuHandle = e => {
        const target = e.target;
        const catalogMenuLink = document.querySelectorAll('.catalog-menu__link--open');

        if (target.matches('.catalog-menu__link--open')) {
            e.preventDefault();
            catalogMenuLink.forEach(link => { // Проверяем есть ли уже открытая менюшка
                if (link.classList.contains('active')) {
                    link.classList.remove('active')
                }
            });
            target.classList.add('active');
        }
    };

    catalogMenu.addEventListener('click', openSubmenuHandle);

};

export default openSubmenu;