const openMap = () => {
   const mapBtnOpen = document.querySelector('.address');

    const openMapHandle = () => {
        const modal = document.querySelector('.page-modal');
        const mapModal = document.querySelector('.map-modal');

        modal.classList.add('active');
        mapModal.classList.add('active');
    };

    mapBtnOpen.addEventListener('click', openMapHandle);
};

export default openMap;