const search = () => {
    const search = document.querySelector('.search');
    const searchDropList = document.querySelector('.search-drop-list');
    const ul = searchDropList.querySelector('ul');

    const debounce = (fn, msec) => {
        let lastCall = 0,
            lastCallTimer = 0;

        return (...args) => {
            const prevCall = lastCall;
            lastCall = Date.now();
            if (prevCall && (lastCall - prevCall) < msec) {
                clearTimeout(lastCallTimer)
            }
            lastCallTimer = setTimeout(() => {
                fn(...args)
            }, msec)
        }
    };

    const showSearchText = (elem) => {
        // запрос на сервер

        ul.innerHTML = `
            <li><a href="/">${elem.value}</a></li> 
            <li><a href="/">${elem.value}</a></li> 
            <li><a href="/">${elem.value}</a></li> 
        `; // в фигурных скобках вставить результат поиска
    };

    const showTextDebounce = debounce(showSearchText, 500);

    search.addEventListener('input', () => {

        if (search.value.length >= 3) {
            search.classList.add('active');
            searchDropList.classList.add('active');
            showTextDebounce(search)
        }
    });

    search.addEventListener('blur', () => {
        search.classList.remove('active');
        searchDropList.classList.remove('active');
        ul.innerHTML = '';
    });
};

export default search;

