const toggleProps = () => {
    const goodPropsWrap = document.querySelectorAll('.good-prop__wrap');

    goodPropsWrap.forEach(goodPropWrap => {
        goodPropWrap.addEventListener('click', (e) => {
            const target = e.target;
            const goodProp = goodPropWrap.querySelector('.good-data__prop')

            if (target.matches('.good-prop__button--show')) {
                goodProp.classList.toggle('active');
                target.style.display = 'none';
                target.nextElementSibling.style.display = 'block';
            }

            if (target.matches('.good-prop__button--hide')) {
                goodProp.classList.toggle('active');
                target.style.display = 'none';
                target.previousElementSibling.style.display = 'block';
            }
        });
    });

};

export default toggleProps;