const closeSubmenu = () => {
    const submenus = document.querySelectorAll('.catalog-submenu');

    submenus.forEach(submenu => {
        submenu.addEventListener('click', e => {
            let target = e.target;

            if (target.closest('.back')) {
                submenu.previousElementSibling.classList.remove('active');
            } else {
                target = target.closest('.catalog-submenu-inner');
                if (!target) {
                    submenu.previousElementSibling.classList.remove('active');
                }
            }
        });
    });

};

export default closeSubmenu;