const goodCounter = () => {
   const goodCounters = document.querySelectorAll('.good-counter');

   goodCounters.forEach(counter => {
      const goodCounterHandler = (e) => {
         const target = e.target;

         if (target.matches('.good-data__minus')) {
            target.nextElementSibling.value = target.nextElementSibling.value - 1;
            if (+target.nextElementSibling.value < 0) {
               target.nextElementSibling.value = 0;
            }
         }

         if (target.matches('.good-data__plus')) {
            target.previousElementSibling.value = +target.previousElementSibling.value + 1;
         }
      };

      counter.addEventListener('click', goodCounterHandler);
   });
};

export default goodCounter;