import {gsap} from "gsap";
import searchCheckoutBtn from "./searchCheckoutBtn";

export const checkoutDesktop = btn => {
    // Создаем timeline, чтобы удобнее выполнять анимацию последрвательно
    const checkoutTL = new gsap.timeline({
        paused: true
    });

    //Вызываем ф-цию поиска элементов и деструктурируем массив
    const [btnText, btnIcon, btnSuccess] = searchCheckoutBtn(btn);

    //Последовательно выполняем анимацию
    checkoutTL
        .to(btnText, {
            opacity: 0,
            duration: 0.75,
            ease: "power4.in"
        }) //Текст на кнопке исчез
        .to(
            btnIcon,
            {
                x: 150,
                delay: 0.25,
                duration: 0.75,
                opacity: 0,
                ease: "back.in(1.7)"
            },
            "<"
        ); //Корзина поехала

    //На все кнопки вешаем клик по которому:
    btn.addEventListener("click", () => {

        checkoutTL
            .to(btn, {
                ease: "power4.out",
            })
            .to(
                btnSuccess,
                {
                    opacity: 1,
                    ease: "power4.out",
                    delay: 0.25
                },
                "<"
            ); //Показываем текст "добавлено"


        checkoutTL.play(); // Запускаем таймлайн

        setTimeout(() => {
            checkoutTL.restart();
            checkoutTL.pause();
        }, 3000);
    });
};








