const fancybox = () => {
    if (window.screen.availWidth > 1000) {
        // fancybox подключается через динамический импорт только при разрешении выше 1000
        import('@fancyapps/ui').then(data => {
            data.Fancybox.bind("[data-fancybox]", {
                // Your options go here
            });
        });

    }
};

export default fancybox;